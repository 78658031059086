function Home() {
  return (
    <h1 className="text-3xl font-bold underline">
      This page is ripe for marketing... or we can just redirect straight to
      login
    </h1>
  );
}

export default Home;
